<template>
  <div>
    <Subview
      :title="hideTitle ? undefined : patientName"
      :messageType="messageType"
      :message="message"
      :showMessage="showMessage"
      @hide="showMessage = false"
      :loading="isLoading"
      :contentPadding="false"
      :class="[currentTabShort !== 'karta' && 'main-content settings', $vuetify.breakpoint.xsOnly && 'mb-6']"
      customContentPadding="px-0 pb-2 pt-0"
      back
    >
      <DefaultTabs
        v-model="activeTab"
        :hide-slider="true"
        slot="subview-content"
        arrowMargins="15px"
        show-arrows
        showLine
      >
        <DefaultTab
          v-for="tab in tabs"
          :key="tab.name"
          :value="tab.name"
          :badge="tab.badge"
          :active="tab === tabs[activeTab]"
          :blockCondition="unsavedData"
          @blocked="handleBlockedTab"
        ></DefaultTab>
        <v-tabs-items 
          :class="$vuetify.breakpoint.smAndUp && 'patient-view-tab-content'" 
          touchless 
          v-model="activeTab" 
          :style="{maxHeight: $vuetify.breakpoint.smAndUp && `calc(100vh - ${currentTabShort !== 'pliki' ? '170px' : '330px'}) !important`}"
        >
          <v-tab-item>
            <v-form>
              <keep-alive>
                <component
                  v-if="patient"
                  :is="'PatientCard'"
                  ref="patientCard"
                  :isLoading.sync="cardLoading"
                  :patient="patient"
                  @success="(x) => handleSuccess(x)"
                  @error="(x) => handleError(x)"
                  @active="(x) => (activeSession = x)"
                  @uploaded="handleUploadResult"
                ></component>
              </keep-alive>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <PatientDetailsCard
              ref="patientData"
              v-if="currentTabShort === 'dane' && patient"
              :value.sync="patient"
              :isLoading.sync="dataLoading"
              @success="handleSavedPatientData"
              @error="handleError"
            ></PatientDetailsCard>
          </v-tab-item>
          <v-tab-item>
            <v-layout v-if="currentTabShort === 'pliki'" column>
              <FileUploadForm
                @uploaded="handleUploadResult"
                class="mt-6 mx-4"
              ></FileUploadForm>
              <PatientDocuments
                :patient="patient"
                :activeFilesOption.sync="fileOptions[activeFilesOption].option"
                @deleted="handleDeleteResult"
                :isLoading.sync="filesListLoading"
                @success="(x) => handleSuccess(x)"
                @error="(x) => handleError(x)"
                ref="documents"
                class="mt-6 mx-4"
              ></PatientDocuments>
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <InfoAgreementsCard
              v-if="currentTabShort === 'zgody'"
              :isLoading.sync="infoLoading"
              @success="handleSavedPatientData"
              @error="handleError"
            ></InfoAgreementsCard>
          </v-tab-item>
        </v-tabs-items>
      </DefaultTabs>
      <RepositoryAddressCard
        v-if="currentTabShort === 'pliki'"
        slot="middle-subview-content"
        @success="handleSuccess"
        @error="handleError"
      ></RepositoryAddressCard>
    </Subview>
    <!-- Using UnsavedDataMixin properties -->
    <ConfirmDialog
      v-if="showUnsavedDialog"
      :value.sync="showUnsavedDialog"
      positiveButtonText="Tak"
      negativeButtonText="Nie"
      :positiveAction="ignoreAction"
      title="Niezapisane zmiany"
      :text="unsavedDialogText"
    ></ConfirmDialog>
    <!--  -->
  </div>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import PatientService from "@/services/patient.service";
import UnsavedDataMixin from "@/mixins/UnsavedDataMixin";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [CRUDMixin, DataTableMixin, UnsavedDataMixin],
  data() {
    return {
      activeFilesOption: 0,
      dataLoading: false,
      infoLoading: false,
      filesListLoading: false,
      appointmentListLoading: false,
      notificationListLoading: false,
      activeSession: true,
      cardLoading: false,
      patientCardLoading: false,
      patient: null,
      documentsCount: 0,
      startTab: null,
      created: false,
      hideTitle: localStorage.getItem("BACK") === "settings"
    };
  },
  components: {
    DefaultTabs: () => import("@/components/tabs/DefaultTabs"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    Subview: () => import("@/components/Subview"),
    FileUploadForm: () => import("@/components/FileUploadForm"),
    PatientDocuments: () => import("@/components/PatientDocuments"),
    PatientCard: () => import("@/components/PatientCard"),
    InnerTabs: () => import("@/components/tabs/InnerTabs"),
    InnerDefaultTab: () => import("@/components/tabs/InnerDefaultTab"),
    DefaultTab: () => import("@/components/tabs/DefaultTab"),
    RepositoryAddressCard: () =>
      import("@/components/cards/files/RepositoryAddressCard"),
    PatientDetailsCard: () => import("@/components/cards/PatientDetailsCard"),
    InfoAgreementsCard: () => import("@/components/cards/InfoAgreementsCard"),
  },
  computed: {
    ...mapGetters("ui", ["patientDetailsActiveTab"]),
    contentPadding() {
      return (
        this.currentTabShort !== "karta" && this.currentTabShort !== "pliki"
      );
    },
    tabs() {
      return this.patient
        ? [
            {
              name: "Karta pacjenta",
              short: "karta",
            },
            {
              name: "Dane pacjenta",
              short: "dane",
            },
            {
              name: "Zdjęcia i pliki",
              short: "pliki",
              badge:
                this.documentsCount != 0
                  ? { content: this.documentsCount, color: "secondary" }
                  : undefined,
            },
            {
              name: "Informacje i zgody",
              short: "zgody",
            },
          ]
        : [];
    },
    fileOptions() {
      let fileOptions = [];
      [
        {
          name: "Mój kalendarz",
          visible: true,
          option: "doctor",
          id: 1,
        },
        // {
        //   name: "Wszyscy pracownicy",
        //   option: "clinic",
        //   id: 2,
        // },
      ].forEach((element) => {
        if (element.visible === undefined || element.visible) {
          fileOptions.push(element);
        }
      });
      return fileOptions;
    },
    isLoading() {
      switch (this.currentTabShort) {
        case "dane":
          return this.dataLoading || this.cardLoading;
        case "wizyty":
          return this.appointmentListLoading;
        case "czat":
          return this.notificationListLoading;
        case "pliki":
          return this.filesListLoading;
        case "zgody":
          return this.infoLoading;
        default:
          return this.loading;
      }
    },
    activeTab: {
      get() {
        return this.patientDetailsActiveTab;
      },
      set(value) {
        if (this.patientDetailsActiveTab != value) {
          if (this.patientDetailsActiveTab == 1) {
            this.documentsCount = 0;
            this.getDocumentsCount();
          }
        }
        this.updatePatientDetailsActiveTab(value);
      },
    },
    patientName() {
      if (this.patient) {
        const name = `${this.patient.first_name} ${this.patient.last_name}`;
        return name;
      }
      return "Karta pacjenta";
    },
    currentTabShort() {
      return this.tabs.length > this.activeTab
        ? this.tabs[this.activeTab].short
        : "";
    },
  },
  methods: {
    ...mapActions("ui", ["updatePatientDetailsActiveTab"]),
    fetch(msg) {
      this.beforeRequest();
      return new Promise((resolve, reject) => {
        PatientService.getPatient()
          .then((x) => {
            this.patient = x;

            this.setStartTab();

            if (msg) {
              this.handleSuccess(msg);
            } else {
              this.reset();
            }

            resolve();
          })
          .catch((x) => {
            this.handleError(x);
            reject();
          });
      })
    },
    handleSavedPatientData(msg) {
      this.fetch(msg);
    },
    handleUploadResult(result) {
      if (result) {
        this.handleSuccess("Plik został wysłany.");
        if (this.$refs.documents) {
          this.$refs.documents.fetchData();
        } else {
          this.getDocumentsCount();
        }
      } else {
        this.handleError("Nie udało się wysłać pliku.");
      }
    },
    handleDeleteResult(result) {
      if (result) {
        this.handleSuccess("Plik został usunięty.");
      } else {
        this.handleError("Nie udało się usunąć pliku.");
      }
    },
    getDocumentsCount() {
      PatientService.getDocumentsCount()
        .then((reply) => {
          this.documentsCount = reply.count;
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    setStartTab() {
      if (this.startTab === null) {
        if (
            !this.patient.pesel || 
            !this.patient.birth_date || 
            !this.patient.city ||
            !this.patient.zipcode ||
            !this.patient.street ||
            !this.patient.house_number
        ) {
          this.startTab = 1;
        } else {
          this.startTab = 0;
        }

        this.activeTab = this.startTab;
      }
    },
  },
  created() {
    this.hideTitle = localStorage.getItem("BACK") === "settings";
    this.fetch().then(() => {
      this.created = true;
      this.getDocumentsCount()
    });
  },
  mounted() {
    if (this.created) {
      this.getDocumentsCount();
    }
  },
};
</script>

<style lang="scss" scoped>
.patient-view-tab-content {
  background-color: transparent;
  overflow: auto !important;
}
</style>